import {
    FINAL_SAVE_SUCCESS,
    FINAL_SAVE_FAILURE
} from '../action/type';

const INIT_STATE = {};

export default function (state = INIT_STATE, action) {
    switch (action.type) {
        case FINAL_SAVE_SUCCESS:

            state = action.payload;
            state.isFinalDataSave = true;
            return state;

        case FINAL_SAVE_FAILURE:

            state = {
                isFinalDataSave: false,
                errorMessage : action.payload.message
            }
            return state;

        default :
            return state;
    }
}