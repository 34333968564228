import {
    TERMSANDCONDITION_SAVE_SUCCESS,
    TERMSANDCONDITION_SAVE_FAILURE
} from '../action/type';

const INIT_STATE = {};

export default function (state = INIT_STATE, action) {
    switch (action.type) {
        case TERMSANDCONDITION_SAVE_SUCCESS:
            state = {
                isTermsandconditionSave : true
            };
            return state;
        case TERMSANDCONDITION_SAVE_FAILURE:

            state = {
                isTermsandconditionSave: false,
                errorMessage : action.payload.message
            };
            return state;

        default :
            return state;
    }
}