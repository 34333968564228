import {
   IMAGE_UPLOAD_SUCCESS,
   IMAGE_UPLOAD_FAILURE
} from '../action/type';

const INIT_STATE = {};

export default function (state = INIT_STATE, action) {
    switch (action.type) {
        case IMAGE_UPLOAD_SUCCESS:

            state = action.payload;
            state.isImageUpload = true;
            return state;

        case IMAGE_UPLOAD_FAILURE:

            state = {
                isImageUpload: false,
                errorMessage : action.payload.message
            }
            return state;

        default :
            return state;
    }
}