import React,{Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { MDBBtn , MDBModal , MDBModalBody , MDBModalFooter} from "mdbreact";
import { Image , Button , Input ,Dimmer , Loader , TextArea} from 'semantic-ui-react';
import SignaturePad from 'react-signature-canvas';
import Resizer from 'react-image-file-resizer';


import { FinalData } from '../action/finaldata';
import { GetOneCandidate } from '../action/login';

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            assetCodeOnChange : '',
            serialNumber : '',
            assetCodeImageUrl : '',
            serialNumberImageUrl :'',
            latitude : '',
            longitude : '',
            signatureURL : '',
            givenAddress:'',
            assetCodeImageFile:'',
            serialNumberImageFile : '',
            finalDataSaveError : false,
            finalDataSaveErrorMessage : '',
            finalDataSaveSuccess : false,
            finalDataSaveSuccessMessage : '',
            isUploading : false,
            showLocationErrorMessage : false
        };
        this.getMyLocation = this.getMyLocation.bind(this)
    }

    componentDidMount() {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event){
            window.history.pushState(null, document.title,  window.location.href);
        });
        this.getMyLocation();
        if(this.props.login && this.props.login.matrixRefNo){
            this.props.GetOneCandidate(this.props.login.matrixRefNo , this.props.login.employeeID);
        }else{
            this.props.history.push(`/login`);
        }
    }

    componentWillReceiveProps(nextProps){
        this.setState({ isUploading : false });
        if(nextProps.login && nextProps.login.isGetOneCandidate && !nextProps.finaldata.errorMessage){
            if(nextProps.login && nextProps.login.status !== "Submitted"){
                this.setState({

                });
            }else{
                this.props.history.push(`/thankyou`);
            }
        }
        
        if(nextProps.finaldata && nextProps.finaldata.isFinalDataSave){
            this.setState({
                finalDataSaveSuccess : true,
                finalDataSaveSuccessMessage : "Verification details uploaded successfully.",
            });
            this.props.history.push(`/thankyou`);
        }else if(nextProps.finaldata.errorMessage){
            this.setState({ finalDataSaveError : true , finalDataSaveErrorMessage : nextProps.finaldata.errorMessage });
        }else if(nextProps.imageupload.errorMessage){
            this.setState({ finalDataSaveError : true , finalDataSaveErrorMessage : nextProps.imageupload.errorMessage });
        }

    }

    getMyLocation() {

        const location = window.navigator && window.navigator.geolocation;
        if (location) {
          location.getCurrentPosition((position) => {
            this.setState({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            })
          }, (error) => {
            this.setState({showLocationErrorMessage : true , latitude: '', longitude: '' });
          });
        };
    
    };
        

    closeLocationErrorMessage =() =>{
        this.setState({showLocationErrorMessage :false });
        this.getMyLocation();
    }

    sigPad = {}

    clear = () => {
        this.sigPad.clear();
    };

    sign = () => {
        this.setState({signatureURL: this.sigPad.getTrimmedCanvas().toDataURL('image/png')});
    };


    assetCodeImageChange = (event) => {
        this.setState({assetCodeImageFile : event.target.files[0]});
        Resizer.imageFileResizer(
            event.target.files[0],
            300,
            300,
            'JPEG',
            100,
            0,
            uri => {
                this.setState({ assetCodeImageUrl: uri });
            },
            'base64'
        );
    };

    serialNumberImageChange = (event) => {
        this.setState({serialNumberImageFile : event.target.files[0]});
        Resizer.imageFileResizer(
            event.target.files[0],
            300,
            300,
            'JPEG',
            100,
            0,
            uri => {
                this.setState({ serialNumberImageUrl: uri });
            },
            'base64'
        );
    };

    assetCodeOnChange = (e) =>{
        this.setState({assetCode : e.target.value});
    };

    serialNumberOnChange = (e) =>{
        this.setState({serialNumber : e.target.value});
    };

    addressOnChange = (e) =>{
        this.setState({givenAddress : e.target.value});
    };

    changeSignature = () =>{
        this.setState({signatureURL : ''});
    };

    successCloseButton =() =>{
        this.setState({ finalDataSaveSuccess : false});
        this.props.GetOneCandidate(this.props.login.matrixRefNo , this.props.login.employeeID);
    };

    errorCloseButton =() =>{
        this.setState({ finalDataSaveError : false});
    };


    submit = () =>{
 
        this.setState({isUploading : true});

        var formData = new FormData();
        formData.append('longitude',this.state.longitude) ;
        formData.append('latitude',this.state.latitude);
        formData.append('signatureURL',this.state.signatureURL);
        formData.append('assetCode',this.state.assetCode);
        formData.append('assetCodeImageUrl',this.state.assetCodeImageUrl);
        formData.append('serialNumber',this.state.serialNumber);
        formData.append('serialNumberImageUrl',this.state.serialNumberImageUrl);
        formData.append('employeeID',this.props.login.employeeID);
        formData.append('matrixRefNo',this.props.login.matrixRefNo);
        formData.append('givenAddress',this.state.givenAddress);
        formData.append('assetCodeImageFile' , this.state.assetCodeImageFile);
        formData.append('serialNumberImageFile' , this.state.serialNumberImageFile);

        this.props.FinalData(formData);
    };


    render() {

        return (
                
                <div>
                    <Dimmer active={this.state.isUploading}>
                        <Loader size="large" active inline>
                            <p>
                                Dont press back or refresh button.
                            </p>
                            <p>
                                Wait while your details get uploaded.
                            </p>
                        </Loader>
                    </Dimmer>
                    <div style={{padding : 10 , marginBottom : 80}}>
                        <div style={{padding : 10 , border:'1px solid #3f81d5' }}>
                            <h4 style={{textAlign:'center'}}>Personal Details</h4>
                            <div>
                                <div className={'from_date'}>
                                    <label style={{fontSize : 16}}><b>Name </b></label>
                                    <p> {this.props.login.candidateName} </p>
                                </div>
                                <div className={'from_date'}>
                                    <label style={{fontSize : 16}}><b>Mobile Number </b></label>
                                    <p> {this.props.login.mobileNumber} </p>
                                </div>    
                            </div>
                            <div style={{marginTop : 10}}>
                                <label style={{fontSize : 16}}><b>Address </b></label>
                                <p> {this.props.login.address} </p>
                            </div>
                        </div>
                        <div style={{padding : 10 , border:'1px solid #3f81d5' , marginTop : 10 , textAlign:'center'} }>
                            <h4 style={{textAlign:'center'}}>Asset code</h4>
                            <Input value={this.state.assetCode} style={{width:'100%' , border:'1px solid rgba(34,36,38,.15)'}} onChange={(e) => this.assetCodeOnChange(e)}></Input>
                        </div>
                        <div style={{padding : 10 , border:'1px solid #3f81d5' , marginTop : 10 , textAlign:'center'} }>
                            <h4 style={{textAlign:'center'}}>Image of asset with asset code</h4>
                            <div style={!this.state.assetCodeImageUrl ? {} : {display : 'none'}}>
                                <input type="file" ref={(ref) => this.upload = ref} onChange={this.assetCodeImageChange} style={{ display: 'none' }} accept="image/*capture=camera"/>
                                <Button style={{marginTop:10 ,backgroundColor:'#3f81d5' ,color : 'white'}} onClick={(e) => this.upload.click() }>Upload Image</Button>
                            </div>
                            <div style={this.state.assetCodeImageUrl ? {padding : 10} : {display : 'none'}}>
                                <Image height={200} width={'80%'} src={this.state.assetCodeImageUrl}></Image>
                                <Button style={{marginTop:10 ,backgroundColor:'#3f81d5' ,color : 'white'}} onClick={ (e) => this.upload.click() }>Change Image</Button>
                            </div>
                        </div>
                        <div style={{padding : 10 , border:'1px solid #3f81d5' , marginTop : 10 , textAlign:'center'} }>
                            <h4 style={{textAlign:'center'}}>Serial Number</h4>
                            <Input value={this.state.serialNumber} style={{width:'100%' , border:'1px solid rgba(34,36,38,.15)'}} onChange={(e) => this.serialNumberOnChange(e)}></Input>
                        </div>
                        <div style={{padding : 10 , border:'1px solid #3f81d5' , marginTop : 10 , textAlign:'center'} }>
                            <h4 style={{textAlign:'center'}}>Image of asset with serial number</h4>
                            <div style={!this.state.serialNumberImageUrl ? {} : {display : 'none'}}>
                                <input type="file" ref={(ref) => this.upload1 = ref} onChange={this.serialNumberImageChange} style={{ display: 'none' }} accept="image/*capture=camera"/>
                                <Button style={{marginTop:10 ,backgroundColor:'#3f81d5' ,color : 'white'}} onClick={(e) => this.upload1.click() }>Upload Image</Button>
                            </div>
                            <div style={this.state.serialNumberImageUrl ? {padding : 10} : {display : 'none'}}>
                                <Image height={200} width={'80%'} src={this.state.serialNumberImageUrl}></Image>
                                <Button style={{marginTop:10 ,backgroundColor:'#3f81d5' ,color : 'white'}} onClick={ (e) => this.upload1.click() }>Change Image</Button>
                            </div>
                        </div>
                        <div style={{padding : 10 , border:'1px solid #3f81d5' , marginTop : 10 , textAlign:'center'} }>
                            <h4 style={{textAlign:'center'}}>Address</h4>
                            <TextArea value={this.state.givenAddress} style={{width:'100%' , border:'1px solid rgba(34,36,38,.15)'}} onChange={(e) => this.addressOnChange(e)}></TextArea>
                        </div>
                        <div style={ !this.state.signatureURL ? {padding : 10 , border:'1px solid #3f81d5' , marginTop : 10 , textAlign:'center' ,marginBottom : 50  } : {display :'none'}}>
                            <h4>Signature</h4>
                            <div style={{border:'2px solid rgba(34,36,38,.15)' ,  marginTop:10 , marginBottom : 10}}>
                                <SignaturePad canvasProps={{width: 300, height: 100 , className: 'sigCanvas'}} ref={(ref) => { this.sigPad = ref }} />
                            </div>
                            <Button style={{backgroundColor:'#3f81d5' ,color : 'white'}} onClick={this.sign}> Save </Button>
                            <Button style={{backgroundColor:'#3f81d5' ,color : 'white'}} onClick={this.clear}> Clear</Button>
                        </div>
                        <div style={this.state.signatureURL ? {padding : 10 , textAlign :'center' ,border:'1px solid #3f81d5' , marginTop : 10 ,marginBottom : 50} : {display : 'none'}}>
                            <h4>Signature</h4>
                            <div style={{border:'2px solid rgba(34,36,38,.15)' ,  marginTop:10 , marginBottom : 10}}>
                                <Image height={100} width={'70%'} src={this.state.signatureURL}></Image>
                            </div>
                            <Button style={{backgroundColor:'#3f81d5' , marginTop:10 ,color : 'white' }} onClick={this.changeSignature}> Change Signature</Button>
                        </div>
                    </div>

                    <Button className={'proceed_btn'} style={{color : 'white'}} onClick={this.submit} >Submit</Button>

                    <MDBModal isOpen={this.state.finalDataSaveSuccess} centered size="sm">
                        <MDBModalBody className="text-center">
                            {this.state.finalDataSaveSuccessMessage}
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="primary" onClick={this.successCloseButton}>Close</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>

                    <MDBModal isOpen={this.state.finalDataSaveError} centered size="sm">
                        <MDBModalBody className="text-center">
                            {this.state.finalDataSaveErrorMessage}
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="primary" onClick={this.errorCloseButton}>Close</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                    <MDBModal isOpen={this.state.showLocationErrorMessage} centered size="sm">
                        <MDBModalBody className="text-center">
                            Please turn on location in your device
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="primary" onClick={this.closeLocationErrorMessage}>Close</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        login: state.login,
        finaldata : state.finaldata,
        imageupload : state.imageupload
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            FinalData,
            GetOneCandidate
        },
        dispatch
    );
};
export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Home)
);