import axios from 'axios';

import {
    FINAL_SAVE_SUCCESS,
    FINAL_SAVE_FAILURE,
    TERMSANDCONDITION_SAVE_SUCCESS,
    TERMSANDCONDITION_SAVE_FAILURE
} from './type.js';

import config from './../config.js';

export const FinalData = (data ) =>{
    return function (dispatch) {
        let request_params = {
            method : 'POST',
            url : config.api_url+"/finalData",
            headers : {
                'content-type': 'multipart/form-data',
                'x-asset-verification-auth' : config.Authorization
            },
            data : data
        };
        axios.request(request_params).then(function(response) {
            if(response.data.statusCode === 200){
                dispatch({
                    type: FINAL_SAVE_SUCCESS,
                    payload: response.data.candidate
                });
            }else{
                dispatch({
                    type: FINAL_SAVE_FAILURE,
                    payload: {message :response.data.message }
                }); 
            }
        })
        .catch(function(error) {
            dispatch({
                type: FINAL_SAVE_FAILURE,
                payload: {message : error.message}
            });
        });
    }        
};

export const TermsAndConditionSave = (data ) =>{
    return function (dispatch) {
        let request_params = {
            method : 'POST',
            headers : {
                'x-asset-verification-auth' : config.Authorization
            },
            url : config.api_url+"/termsAndConditionSave",
            data : data
        };
        axios.request(request_params).then(function(response) {
            if(response.data.statusCode === 200){
                dispatch({
                    type: TERMSANDCONDITION_SAVE_SUCCESS,
                    payload: response.data.candidate
                });
            }else{
                dispatch({
                    type: TERMSANDCONDITION_SAVE_FAILURE,
                    payload: {message :response.data.message }
                }); 
            }
        })
        .catch(function(error) {
            dispatch({
                type: TERMSANDCONDITION_SAVE_FAILURE,
                payload: {message : error.message}
            });
        });
    }        
}