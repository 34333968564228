import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route } from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import reducers from './reducers/index';
import { Provider } from 'react-redux';
import reduxThunk from 'redux-thunk';
import 'semantic-ui-css/semantic.min.css';
import './main.css';
import { saveState,loadState } from './util.js';
import Throttle from 'lodash/throttle';
import * as serviceWorker from './serviceWorker';
import Login from './components/login';
import OTP from './components/otp';
import Termsandconditions from './components/termsandconditions';
import Home from './components/home';
import ThankYou from './components/thankyou';

import '@fortawesome/fontawesome-free/css/all.min.css'; import
'bootstrap-css-only/css/bootstrap.min.css'; import
'mdbreact/dist/css/mdb.css';

const persistedState = loadState();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducers, persistedState, composeEnhancers(applyMiddleware(reduxThunk)));

store.subscribe(
    Throttle(() => {
        saveState({
          login: store.getState().login
        });
    }, 1000)
);

const App = () => (
  <div style={{position:'relative'}}>
    <Route path="/" exact component={Login} />
    <Route exact path="/login" component={Login} />
    <Route exact path="/otp" component={OTP} />
    <Route exact path="/termsandconditions" component={Termsandconditions} />
    <Route exact path="/home" component={Home} />
    <Route exact path="/thankyou" component={ThankYou} />
  </div>
);

ReactDOM.render(
  <Provider store={store}>
      <HashRouter>
          <App />
      </HashRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

