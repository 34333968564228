import React,{Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { MDBBtn , MDBModal , MDBModalBody , MDBModalFooter} from "mdbreact";
import OtpInput from 'react-otp-input';
import { Image , Button } from 'semantic-ui-react';
import { VerifyOTP , SendOTP} from '../action/login';

class OTP extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mobileNumber : '',
            matrixRefNo : '',
            otp : '',
            otpError : false,
            otpverifyError : false,
            otpverifyErrorMessage : '',
            isResendOtp : false,
            employeeID : ''
        };
    }

    componentDidMount() {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event){
            window.history.pushState(null, document.title,  window.location.href);
        });
        if(this.props.login && this.props.login.mobileNumber && this.props.login.matrixRefNo){
            this.setState({ mobileNumber : this.props.login.mobileNumber , matrixRefNo : this.props.login.matrixRefNo , employeeID : this.props.login.employeeID});
        }else{
            this.props.history.push(`/login`);
        }
    }

    componentWillReceiveProps(nextProps){

        if(nextProps.login && nextProps.login.isOtpResent){
            this.setState({ isResendOtp : true });
            this.setState({ otp : '' });
        }else if(nextProps.login && nextProps.login.isOtpVerify ){
            this.setState({ otp : '' });
            this.props.history.push(`/termsandconditions`);
        }else {
            this.setState({ otp : '' });
            this.setState({  otpverifyError : true , otpverifyErrorMessage : nextProps.login.errorMessage });
        }

    };

    editNumber = () =>{
        this.props.history.push(`/login`);
    };

    verify = () =>{
        var mobileNumber = this.state.mobileNumber;
        var matrixRefNo = this.state.matrixRefNo;
        var employeeID = this.state.employeeID;
        var otp = this.state.otp;

        if(otp && otp.toString().length === 4 ){
            this.props.VerifyOTP(mobileNumber , matrixRefNo , otp , employeeID);
        }else{
            this.setState({otpError : true });
        }
    };

    handleOtpChange = otp => {
        this.setState({ otp });
        this.setState({ otpError : false });
    };

    closeButton =() =>{
        this.setState({ otpverifyError : false});
    };

    closeResendButton =() =>{
        this.setState({ isResendOtp : false});
    };

    resendOtp =() =>{
        var mobileNumber = this.state.mobileNumber;
        var matrixRefNo = this.state.matrixRefNo;
        var employeeID = this.state.employeeID;
        this.props.SendOTP(mobileNumber , true , matrixRefNo , employeeID);
    };


    render() {
        return (
                <div>
                    <div style={{textAlign:'center' , padding:20}}>
                        <Image width={100} src={process.env.PUBLIC_URL + "/assets/images/1.png"} />
                    </div>
                    <div style={{textAlign:'center' , padding:20}}>
                        <h2> Enter OTP</h2>
                    </div>
                    <div style={{ paddingTop:20 , display : 'flex' , marginLeft : '25%'}}>
                        <p><b style={{fontSize:20}}> Sent to +91 {this.state.mobileNumber}</b></p>
                        {/* <Icon style={{paddingLeft : 20 , paddingBottom : 20}}  name='edit'></Icon> */}
                    </div>
                    <div style={{textAlign:'center'}}>
                        <p style={{color : "#3f81d5"}} onClick={this.editNumber} >Change Number</p>
                    </div>
                    <div style={{ marginLeft : '10%'  , paddingTop : 20}}>
                        <OtpInput
                            numInputs={4}
                            separator={<span>-</span>}
                            inputStyle={!this.state.otpError ? {
                                width: '3rem',
                                height: '3rem',
                                margin: '0 1rem',
                                fontSize: '1rem',
                                borderRadius: 4,
                                border: '1px solid #3f81d5',
                                color : '#3f81d5'
                              } : {
                                width: '3rem',
                                height: '3rem',
                                margin: '0 1rem',
                                fontSize: '1rem',
                                borderRadius: 4,
                                border: '1px solid red',
                                color : 'red'
                              }}
                              isInputNum={true}
                              value = {this.state.otp}
                              onChange={this.handleOtpChange}
                        />
                    </div>
                    <div style={{ paddingTop:20 , display : 'flex' , marginLeft : '15%'}}>
                        <p>Didn’t receive the OTP?</p>
                        <p style={{color : '#3f81d5' , paddingLeft : 10}} onClick={this.resendOtp}><b>Resend OTP</b></p>
                    </div>
                    <Button className={'proceed_btn'} style={{color : 'white'}} onClick={this.verify} >VERIFY NOW</Button>

                    <MDBModal isOpen={this.state.otpverifyError} centered size="sm">
                        <MDBModalBody className="text-center">
                            {this.state.otpverifyErrorMessage}
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="primary" onClick={this.closeButton}>Close</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>

                    <MDBModal isOpen={this.state.isResendOtp} centered size="sm">
                        <MDBModalBody className="text-center">
                            OTP sent again.
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn  color="primary" onClick={this.closeResendButton}>Close</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        login: state.login,
        resentotp : state.resentotp,
        verifyotp : state.verifyotp,
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            VerifyOTP,
            SendOTP
        },
        dispatch
    );
};
export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(OTP)
);