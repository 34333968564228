export const OTP_SENT_SUCCESS = 'otp_sent_success';
export const OTP_SENT_FAILURE = 'otp_sent_fuilure';
export const OTP_VERIFY_SUCCESS = 'otp_verify_success';
export const OTP_VERIFY_FAILURE = 'otp_verify_fuilure';
export const OTP_RESENT_SUCCESS = 'otp_resent_success';
export const OTP_RESENT_FAILURE = 'otp_resent_fuilure';
export const FINAL_SAVE_SUCCESS = 'final_save_success';
export const FINAL_SAVE_FAILURE = 'final_save_fuilure';
export const IMAGE_UPLOAD_SUCCESS = 'image_upload_success';
export const IMAGE_UPLOAD_FAILURE = 'image_upload_fuilure';
export const GET_ONE_CANDIDATE_SUCCESS = 'get_one_candidate_success';
export const GET_ONE_CANDIDATE_FAILURE = 'get_one_candidate_fuilure';
export const TERMSANDCONDITION_SAVE_SUCCESS = 'termsandcondition_save_success';
export const TERMSANDCONDITION_SAVE_FAILURE = 'termsandcondition_save_fuilure';