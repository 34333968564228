import React,{Component} from 'react';
import { connect } from 'react-redux';
import { SendOTP } from '../action/login';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { MDBBtn , MDBModal , MDBModalBody , MDBModalFooter} from "mdbreact";
import {  Image , Button , Dimmer , Loader } from 'semantic-ui-react';
import {isBrowser} from 'react-device-detect';
import queryString from 'querystring';

class LoginPage extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            mobileNumber : '',
            isMobileNumError : false,
            otpSentError : false,
            otpSentErrorMessge : '',
            matrixRefNo  : '',
            employeeID : '',
            isUploading : false
        };
    }
     
    componentDidMount() {
        let query = queryString.parse(this.props.location.search);
        let matrixRefNo = query['?matrixRefNo'];
        let employeeID = (query['employeeID'] === undefined || query['employeeID'] === 'undefined') ? '' : query['employeeID'] ;
        this.setState({matrixRefNo : matrixRefNo , employeeID : employeeID});
    };

    componentWillReceiveProps(nextProps){
        this.setState({ isUploading  : false });
        console.log(nextProps.login , "nextProps.login")
        if(nextProps.login && nextProps.login.isOtpSent){
            this.props.history.push(`/otp`);
        }else if(!nextProps.login.isOtpSent){
            this.setState({ otpSentError : true , otpSentErrorMessge : nextProps.login.errorMessage });
        }

    }

    handleMobileNumberChange = (e) =>{

        this.setState({mobileNumber : e.target.value ,  isMobileNumError : false});

    };

    proceed = () =>{
        var mobileNumber = this.state.mobileNumber;
        var matrixRefNo = this.state.matrixRefNo;
        var employeeID = this.state.employeeID;
        if(mobileNumber && mobileNumber.length >= 10 && mobileNumber.length <= 11 && matrixRefNo){
            this.setState({ isUploading  : true });
            this.props.SendOTP(mobileNumber ,false, matrixRefNo , employeeID);
        }else{
            this.setState({isMobileNumError : true});
        }   
    };

    closeButton =() =>{
        this.setState({otpSentError : false});
    };

    render() {

        return (
                <div>
                    {!isBrowser && <div>
                        <Dimmer active={this.state.isUploading}>
                            <Loader size="large" active inline>
                                <p>
                                    Dont press back or refresh button.
                                </p>
                            </Loader>
                        </Dimmer>
                        <div style={{textAlign:'center' , paddingTop:20}}>
                            <Image width={250} height={150} src={process.env.PUBLIC_URL + "/assets/images/matrix-logo.png"} />
                        </div>
                        <div style={{textAlign:'center' , padding:5}}>
                            <h3>MATRIX ONLINE ASSET VERIFICATION</h3>
                        </div>
                        <div style={{textAlign:'center' , padding:10 , marginTop:10}}>
                            <h4>For any queries contact 7397427373/8012246669</h4>
                        </div>
                        <div style={{ padding:10  , paddingTop : '35%'}}>
                            <h3 >Let's get started!</h3>
                        </div>
                        <div style={{ padding:10}}>
                            <h5> Enter your registered mobile number to receive an OTP </h5>
                        </div>
                        <div style={{padding : 10 }}>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">+91</div>
                                </div>
                                <input type="number" className="form-control"  onChange={this.handleMobileNumberChange} placeholder="Enter Mobile Number" />
                            </div>
                            <span style={this.state.isMobileNumError ? {color : 'red'} : {display : 'none'}}> Invalid Mobile Number</span>
                        </div>
                        <Button className={'proceed_btn'} style={{color : 'white'}} onClick={this.proceed} >RECEIVE OTP</Button>

                        <MDBModal isOpen={this.state.otpSentError} centered size="sm">
                            <MDBModalBody className="text-center">
                                {this.state.otpSentErrorMessge}
                            </MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn color="primary" onClick={this.closeButton}>Close</MDBBtn>
                            </MDBModalFooter>
                        </MDBModal>
                    </div>}
                    {isBrowser && <div style={{textAlign : 'center'}}>
                        <h2> This link has to be opened from your mobile device </h2>
                    </div> }
                </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        login : state.login
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            SendOTP
        },
        dispatch
    );
};
export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(LoginPage)
);