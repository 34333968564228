import axios from 'axios';

import {
    OTP_SENT_SUCCESS,
    OTP_SENT_FAILURE ,
    OTP_VERIFY_SUCCESS,
    OTP_VERIFY_FAILURE,
    OTP_RESENT_SUCCESS,
    OTP_RESENT_FAILURE,
    GET_ONE_CANDIDATE_SUCCESS, 
    GET_ONE_CANDIDATE_FAILURE
} from './type.js';

import config from './../config.js';

export const SendOTP = (mobileNumber , isResendOTP , matrixRefNo , employeeID) =>{
    return function (dispatch) {
        let request_params = {
            method : 'POST',
            url : config.api_url+"/sendOtp",
            headers : {
                'x-asset-verification-auth' : config.Authorization
            },
            data : {mobileNumber : mobileNumber , matrixRefNo : matrixRefNo , employeeID : employeeID}
        };
        axios.request(request_params).then(function(response) {
            if(response.data.statusCode === 200){
                if(isResendOTP){
                    dispatch({
                        type: OTP_RESENT_SUCCESS,
                        payload: response.data.candidate
                    });
                }else{
                    dispatch({
                        type: OTP_SENT_SUCCESS,
                        payload: response.data.candidate
                    });
                }
            }else{
                if(isResendOTP){
                    dispatch({
                        type: OTP_RESENT_FAILURE,
                        payload: {message :response.data.message }
                    }); 
                }else{
                    dispatch({
                        type: OTP_SENT_FAILURE,
                        payload: {message :response.data.message }
                    }); 
                }          
            }
        })
        .catch(function(error) {
            dispatch({
                type: OTP_SENT_FAILURE,
                payload: {message : error.message}
            });
        });
    }
}

export const VerifyOTP = (mobileNumber ,matrixRefNo, otp , employeeID) =>{
    return function (dispatch) {
        let request_params = {
            method : 'POST',
            url : config.api_url+"/verifyOtp",
            headers : {
                'x-asset-verification-auth' : config.Authorization
            },
            data : {mobileNumber : mobileNumber ,matrixRefNo : matrixRefNo, otp : otp , employeeID : employeeID}
        };
        axios.request(request_params).then(function(response) {

            if(response.data.statusCode === 200){
                dispatch({
                    type: OTP_VERIFY_SUCCESS,
                    payload: response.data.candidate
                });
            }else{
                dispatch({
                    type: OTP_VERIFY_FAILURE,
                    payload: {message :response.data.message }
                });           
            }
        })
        .catch(function(error) {
            dispatch({
                type: OTP_VERIFY_FAILURE,
                payload: {message : error.message}
            });
        });
    }
}

export const GetOneCandidate = (matrixRefNo , employeeID) =>{
    return function (dispatch) {
        let request_params = {
            method : 'POST',
            url : config.api_url+"/getOneCandidate",
            headers : {
                'x-asset-verification-auth' : config.Authorization
            },
            data : {matrixRefNo : matrixRefNo , employeeID : employeeID }
        };
        axios.request(request_params).then(function(response) {

            if(response.data.statusCode === 200){
                dispatch({
                    type: GET_ONE_CANDIDATE_SUCCESS,
                    payload: response.data.candidate
                });
            }else{
                dispatch({
                    type: GET_ONE_CANDIDATE_FAILURE,
                    payload: {message :response.data.message }
                });           
            }
        })
        .catch(function(error) {
            dispatch({
                type: GET_ONE_CANDIDATE_FAILURE,
                payload: {message : error.message}
            });
        });
    }
}