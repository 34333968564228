import { combineReducers} from 'redux';

import loginReducer from './login_reducer';
import finaldataReducer from './finaldata_reducer';
import imageuploadReducer from './imageupload_reducer';
import termsandconditionReducer from './termsandcondition_reducer';

const rootReducer = combineReducers({
    login : loginReducer,
    finaldata : finaldataReducer,
    imageupload : imageuploadReducer,
    termsandcondition : termsandconditionReducer
});

export default rootReducer;