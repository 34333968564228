import React,{Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { Checkbox , Button } from 'semantic-ui-react';
import { MDBBtn , MDBModal , MDBModalBody , MDBModalFooter} from "mdbreact";

import { TermsAndConditionSave } from '../action/finaldata';

class TermsAndConditions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isGetStarted : false,
            latitude : '',
            longitude : '',
            showLocationErrorMessage : false
        };
        this.getMyLocation = this.getMyLocation.bind(this);
    }

    componentDidMount() {
        this.getMyLocation();
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event){
            window.history.pushState(null, document.title,  window.location.href);
        });
    };

    componentWillReceiveProps(nextProps){
        if(nextProps.termsandcondition && nextProps.termsandcondition.isTermsandconditionSave){
            this.props.history.push(`/home`);
        }else{
            this.setState({ isError : true , errMessage : nextProps.termsandcondition.errorMessage});
        }
    }

    getMyLocation() {

        const location = window.navigator && window.navigator.geolocation;
        if (location) {
          location.getCurrentPosition((position) => {
            this.setState({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            })
          }, (error) => {
            this.setState({showLocationErrorMessage : true , latitude: '', longitude: '' });
          });
        };
    
    };
    
    handleAgreeChange = () => {
        this.setState({ isGetStarted : !this.state.isGetStarted });
    };

    getStarted = () => {
        var data = {
            termsandconditionLatitude : this.state.latitude,
            termsandconditionLongitude : this.state.longitude,
            matrixRefNo : this.props.login.matrixRefNo,
            employeeID : this.props.login.employeeID
        }
        this.props.TermsAndConditionSave(data);
    };

    closeLocationErrorMessage =() =>{
        this.setState({showLocationErrorMessage :false });
        this.getMyLocation();
    }

    render() {

        return (
                <div>
                    <h2 style={{paddingTop : 20 , textAlign:'center'}} > Declaration and Consent </h2>
                    <ul style={{paddingTop : 20}}>
                        <li>
                            <p>I hereby agree to have the asset verification conducted by Matrix Business Services India Private Limited digitally.</p>
                        </li>
                        <li>
                            <p>
                                I understand that the asset verification is done on behalf of TCS ad therefore willing to provide all necessary details related to this verification. 
                                I hereby allow to use my location to capture the Latitude and Longitude Coordinates of my address. 
                                I confirm and undertake that Matrix Business Services India Private Limited shall not incur any liability or obligation of any nature whatsoever resulting from this asset verification.
                                I understand that the information furnished/provided by me would be submitted only to TCS.
                            </p>
                        </li>
                        <li>
                            <p>This consent form is digitally signed and construed as physically signed.</p>
                        </li>
                    </ul>
                    <div style={{textAlign : 'center' , paddingTop : 20}}>
                        <Checkbox label='Agree' onClick={this.handleAgreeChange}/>
                    </div>
                    <Button className={'proceed_btn'} style={{color : 'white'}} disabled={!this.state.isGetStarted} onClick={this.getStarted} >GET STARTED</Button>
                    <MDBModal isOpen={this.state.showLocationErrorMessage} centered size="sm">
                        <MDBModalBody className="text-center">
                            We are not able to retrieve your GPS location. Please make sure of the following.Turn on the device location Allow location access to https://matexresidence.matex.co.in in your browser.
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="primary" onClick={this.closeLocationErrorMessage}>Close</MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        login: state.login,
        termsandcondition : state.termsandcondition
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            TermsAndConditionSave
        },
        dispatch
    );
};
export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TermsAndConditions)
);