import React,{Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';

class ThankYou extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }
    componentDidMount() {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event){
            window.history.pushState(null, document.title,  window.location.href);
        });
    }
    render() {
        return (
                <div >
                    <div style={{textAlign : 'center' , paddingTop : '50%' , paddingBottom : '50%'}}>
                        <h3> Thank you for providing the details !</h3>
                    </div> 
                </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        // auth: state.auth
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {

        },
        dispatch
    );
};
export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ThankYou)
);