import {
    OTP_SENT_SUCCESS,
    OTP_SENT_FAILURE,
    OTP_VERIFY_SUCCESS,
    OTP_VERIFY_FAILURE,
    OTP_RESENT_SUCCESS,
    OTP_RESENT_FAILURE,
    GET_ONE_CANDIDATE_SUCCESS,
    GET_ONE_CANDIDATE_FAILURE
} from '../action/type';

import moment from 'moment';

const INIT_STATE = {};

export default function (state = INIT_STATE, action) {
    switch (action.type) {
        case OTP_SENT_SUCCESS:

            state = action.payload;
            state.isOtpSent = true;
            state.expiresIn = moment().add(28800, 'seconds').format();
            return state;

        case OTP_SENT_FAILURE:

            state = {
                isOtpSent: false,
                errorMessage : action.payload.message
            }
            return state;

        case OTP_VERIFY_SUCCESS:

            state = action.payload;
            state.isOtpVerify = true;
            return state;
    
        case OTP_VERIFY_FAILURE:

            state = {
                isOtpVerify: false,
                errorMessage : action.payload.message
            }
            return state;

        case OTP_RESENT_SUCCESS:

            state = action.payload;
            state.isOtpResent = true;
            return state;
    
        case OTP_RESENT_FAILURE:

            state = {
                isOtpResent: false,
                errorMessage : action.payload.message
            }
            return state;
        
        case GET_ONE_CANDIDATE_SUCCESS:

            state = action.payload;
            state.isGetOneCandidate = true;
            return state;
    
        case GET_ONE_CANDIDATE_FAILURE:

            state = {
                isGetOneCandidate: false,
                errorMessage : action.payload.message
            }
            return state;

        default :
            return state;
    }
}